import _network from "./network.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var network = _network;
Object.defineProperty(exports, "CacheControl", {
  enumerable: true,
  get: function () {
    return network.CacheControl;
  }
});
Object.defineProperty(exports, "CspDirective", {
  enumerable: true,
  get: function () {
    return network.CspDirective;
  }
});
Object.defineProperty(exports, "CspSandboxAllow", {
  enumerable: true,
  get: function () {
    return network.CspSandboxAllow;
  }
});
Object.defineProperty(exports, "HashAlgorithm", {
  enumerable: true,
  get: function () {
    return network.HashAlgorithm;
  }
});
Object.defineProperty(exports, "Header", {
  enumerable: true,
  get: function () {
    return network.Header;
  }
});
Object.defineProperty(exports, "Method", {
  enumerable: true,
  get: function () {
    return network.Method;
  }
});
Object.defineProperty(exports, "ResponseType", {
  enumerable: true,
  get: function () {
    return network.ResponseType;
  }
});
Object.defineProperty(exports, "SpecialSource", {
  enumerable: true,
  get: function () {
    return network.SpecialSource;
  }
});
Object.defineProperty(exports, "SriAsset", {
  enumerable: true,
  get: function () {
    return network.SriAsset;
  }
});
Object.defineProperty(exports, "StatusCode", {
  enumerable: true,
  get: function () {
    return network.StatusCode;
  }
});
exports.getResponseType = network.getResponseType;
exports.hashSource = network.hashSource;
exports.noCache = network.noCache;
exports.nonceSource = network.nonceSource;
export default exports;