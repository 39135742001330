var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Method = void 0;

(function (Method) {
  Method["Get"] = "GET";
  Method["Post"] = "POST";
  Method["Put"] = "PUT";
  Method["Patch"] = "PATCH";
  Method["Delete"] = "DELETE";
  Method["Head"] = "HEAD";
  Method["Options"] = "OPTIONS";
  Method["Connect"] = "CONNECT";
})(exports.Method || (exports.Method = {}));

exports.StatusCode = void 0; // Keep this sorted

(function (StatusCode) {
  StatusCode[StatusCode["Continue"] = 100] = "Continue";
  StatusCode[StatusCode["SwitchingProtocols"] = 101] = "SwitchingProtocols";
  StatusCode[StatusCode["Ok"] = 200] = "Ok";
  StatusCode[StatusCode["Created"] = 201] = "Created";
  StatusCode[StatusCode["Accepted"] = 202] = "Accepted";
  StatusCode[StatusCode["NonAuthoritativeInformation"] = 203] = "NonAuthoritativeInformation";
  StatusCode[StatusCode["NoContent"] = 204] = "NoContent";
  StatusCode[StatusCode["ResetContent"] = 205] = "ResetContent";
  StatusCode[StatusCode["PartialContent"] = 206] = "PartialContent";
  StatusCode[StatusCode["MultipleChoices"] = 300] = "MultipleChoices";
  StatusCode[StatusCode["MovedPermanently"] = 301] = "MovedPermanently";
  StatusCode[StatusCode["Found"] = 302] = "Found";
  StatusCode[StatusCode["SeeOther"] = 303] = "SeeOther";
  StatusCode[StatusCode["NotModified"] = 304] = "NotModified";
  StatusCode[StatusCode["UseProxy"] = 305] = "UseProxy";
  StatusCode[StatusCode["TemporaryRedirect"] = 307] = "TemporaryRedirect";
  StatusCode[StatusCode["BadRequest"] = 400] = "BadRequest";
  StatusCode[StatusCode["Unauthorized"] = 401] = "Unauthorized";
  StatusCode[StatusCode["PaymentRequired"] = 402] = "PaymentRequired";
  StatusCode[StatusCode["Forbidden"] = 403] = "Forbidden";
  StatusCode[StatusCode["NotFound"] = 404] = "NotFound";
  StatusCode[StatusCode["MethodNotAllowed"] = 405] = "MethodNotAllowed";
  StatusCode[StatusCode["NotAcceptable"] = 406] = "NotAcceptable";
  StatusCode[StatusCode["ProxyAuthenticationRequired"] = 407] = "ProxyAuthenticationRequired";
  StatusCode[StatusCode["RequestTimeout"] = 408] = "RequestTimeout";
  StatusCode[StatusCode["Conflict"] = 409] = "Conflict";
  StatusCode[StatusCode["Gone"] = 410] = "Gone";
  StatusCode[StatusCode["LengthRequired"] = 411] = "LengthRequired";
  StatusCode[StatusCode["PreconditionFailed"] = 412] = "PreconditionFailed";
  StatusCode[StatusCode["RequestEntityTooLarge"] = 413] = "RequestEntityTooLarge";
  StatusCode[StatusCode["RequestUriTooLong"] = 414] = "RequestUriTooLong";
  StatusCode[StatusCode["UnsupportedMediaType"] = 415] = "UnsupportedMediaType";
  StatusCode[StatusCode["RequestedRangeNotSatisfiable"] = 416] = "RequestedRangeNotSatisfiable";
  StatusCode[StatusCode["ExpectationFailed"] = 417] = "ExpectationFailed";
  StatusCode[StatusCode["ImATeapot"] = 418] = "ImATeapot";
  StatusCode[StatusCode["UnprocessableEntity"] = 422] = "UnprocessableEntity";
  StatusCode[StatusCode["TooManyRequests"] = 429] = "TooManyRequests";
  StatusCode[StatusCode["InternalServerError"] = 500] = "InternalServerError";
  StatusCode[StatusCode["NotImplemented"] = 501] = "NotImplemented";
  StatusCode[StatusCode["BadGateway"] = 502] = "BadGateway";
  StatusCode[StatusCode["ServiceUnavailable"] = 503] = "ServiceUnavailable";
  StatusCode[StatusCode["GatewayTimeout"] = 504] = "GatewayTimeout";
  StatusCode[StatusCode["HttpVersionNotSupported"] = 505] = "HttpVersionNotSupported";
})(exports.StatusCode || (exports.StatusCode = {}));

exports.Header = void 0;

(function (Header) {
  Header["Accept"] = "Accept";
  Header["AcceptEncoding"] = "Accept-Encoding";
  Header["AcceptLanguage"] = "Accept-Language";
  Header["AccessControlAllowCredentials"] = "Access-Control-Allow-Credentials";
  Header["AccessControlAllowHeaders"] = "Access-Control-Allow-Headers";
  Header["AccessControlAllowMethods"] = "Access-Control-Allow-Methods";
  Header["AccessControlAllowOrigin"] = "Access-Control-Allow-Origin";
  Header["AccessControlExposeHeaders"] = "Access-Control-Expose-Headers";
  Header["AccessControlMaxAge"] = "Access-Control-Max-Age";
  Header["AccessControlRequestHeaders"] = "Access-Control-Request-Headers";
  Header["AccessControlRequestMethod"] = "Access-Control-Request-Method";
  Header["Authorization"] = "Authorization";
  Header["CacheControl"] = "Cache-Control";
  Header["CacheStatus"] = "Cache-Status";
  Header["Connection"] = "Connection";
  Header["ContentDisposition"] = "Content-Disposition";
  Header["ContentEncoding"] = "Content-Encoding";
  Header["ContentLength"] = "Content-Length";
  Header["ContentSecurityPolicy"] = "Content-Security-Policy";
  Header["ContentSecurityPolicyReportOnly"] = "Content-Security-Policy-Report-Only";
  Header["ContentType"] = "Content-Type";
  Header["ContentTypeOptions"] = "X-Content-Type-Options";
  Header["Cookie"] = "Cookie";
  Header["DownloadOptions"] = "X-Download-Options";
  Header["ETag"] = "ETag";
  Header["Forwarded"] = "Forwarded";
  Header["ForwardedFor"] = "X-Forwarded-For";
  Header["ForwardedHost"] = "X-Forwarded-Host";
  Header["ForwardedProtocol"] = "X-Forwarded-Proto";
  Header["FrameOptions"] = "X-Frame-Options";
  Header["Host"] = "Host";
  Header["IfNoneMatch"] = "If-None-Match";
  Header["Location"] = "Location";
  Header["Origin"] = "Origin";
  Header["ReferrerPolicy"] = "Referrer-Policy";
  Header["ServerTiming"] = "Server-Timing";
  Header["StrictTransportSecurity"] = "Strict-Transport-Security";
  Header["TimingAllowOrigin"] = "Timing-Allow-Origin";
  Header["Trailer"] = "Trailer";
  Header["TransferEncoding"] = "Transfer-Encoding";
  Header["UserAgent"] = "User-Agent";
  Header["WwwAuthenticate"] = "WWW-Authenticate";
  Header["XhrRedirectedTo"] = "X-XHR-Redirected-To";
  Header["XhrReferer"] = "X-XHR-Referer";
  Header["XssProtecton"] = "X-XSS-Protection";
  Header["XContentTypeOptions"] = "X-Content-Type-Options";
  Header["XDownloadOptions"] = "X-Download-Options";
  Header["XForwardedFor"] = "X-Forwarded-For";
  Header["XForwardedHost"] = "X-Forwarded-Host";
  Header["XForwardedProto"] = "X-Forwarded-Proto";
  Header["XFrameOptions"] = "X-Frame-Options";
  Header["XXhrRedirectedTo"] = "X-XHR-Redirected-To";
  Header["XXhrReferer"] = "X-XHR-Referer";
  Header["XXssProtecton"] = "X-XSS-Protection";
  Header["XXssProtection"] = "X-XSS-Protection";
})(exports.Header || (exports.Header = {}));

exports.CspDirective = void 0;

(function (CspDirective) {
  CspDirective["ChildSrc"] = "child-src";
  CspDirective["ConnectSrc"] = "connect-src";
  CspDirective["DefaultSrc"] = "default-src";
  CspDirective["FontSrc"] = "font-src";
  CspDirective["FrameSrc"] = "frame-src";
  CspDirective["ImgSrc"] = "img-src";
  CspDirective["ManifestSrc"] = "manifest-src";
  CspDirective["MediaSrc"] = "media-src";
  CspDirective["ObjectSrc"] = "object-src";
  CspDirective["PrefetchSrc"] = "prefetch-src";
  CspDirective["ScriptSrc"] = "script-src";
  CspDirective["StyleSrc"] = "style-src";
  CspDirective["WebrtcSrc"] = "webrtc-src";
  CspDirective["WorkerSrc"] = "worker-src";
  CspDirective["BaseUri"] = "base-uri";
  CspDirective["PluginTypes"] = "plugin-types";
  CspDirective["Sandbox"] = "sandbox";
  CspDirective["FormAction"] = "form-action";
  CspDirective["FrameAncestors"] = "frame-ancestors";
  CspDirective["ReportUri"] = "report-uri";
  CspDirective["BlockAllMixedContent"] = "block-all-mixed-content";
  CspDirective["RequireSriFor"] = "require-sri-for";
  CspDirective["UpgradeInsecureRequests"] = "upgrade-insecure-requests";
})(exports.CspDirective || (exports.CspDirective = {}));

exports.CspSandboxAllow = void 0;

(function (CspSandboxAllow) {
  CspSandboxAllow["Forms"] = "allow-forms";
  CspSandboxAllow["SameOrigin"] = "allow-same-origin";
  CspSandboxAllow["Scripts"] = "allow-scripts";
  CspSandboxAllow["Popups"] = "allow-popups";
  CspSandboxAllow["Modals"] = "allow-modals";
  CspSandboxAllow["OrientationLock"] = "allow-orientation-lock";
  CspSandboxAllow["PointerLock"] = "allow-pointer-lock";
  CspSandboxAllow["Presentation"] = "allow-presentation";
  CspSandboxAllow["PopupsToEscapeSandbox"] = "allow-popups-to-escape-sandbox";
  CspSandboxAllow["TopNavigation"] = "allow-top-navigation";
})(exports.CspSandboxAllow || (exports.CspSandboxAllow = {}));

exports.SpecialSource = void 0;

(function (SpecialSource) {
  SpecialSource["Any"] = "*";
  SpecialSource["Self"] = "'self'";
  SpecialSource["UnsafeInline"] = "'unsafe-inline'";
  SpecialSource["UnsafeEval"] = "'unsafe-eval'";
  SpecialSource["None"] = "'none'";
  SpecialSource["StrictDynamic"] = "'strict-dynamic'";
  SpecialSource["ReportSample"] = "'report-sample'";
  SpecialSource["Data"] = "data:";
  SpecialSource["Blob"] = "blob:";
  SpecialSource["FileSystem"] = "filesystem:";
})(exports.SpecialSource || (exports.SpecialSource = {}));

exports.SriAsset = void 0;

(function (SriAsset) {
  SriAsset["Script"] = "script";
  SriAsset["Style"] = "style";
})(exports.SriAsset || (exports.SriAsset = {}));

exports.HashAlgorithm = void 0;

(function (HashAlgorithm) {
  HashAlgorithm["Sha256"] = "sha256";
  HashAlgorithm["Sha384"] = "sha384";
  HashAlgorithm["Sha512"] = "sha512";
})(exports.HashAlgorithm || (exports.HashAlgorithm = {}));

exports.ResponseType = void 0;

(function (ResponseType) {
  ResponseType["Informational"] = "1xx";
  ResponseType["Success"] = "2xx";
  ResponseType["Redirection"] = "3xx";
  ResponseType["ClientError"] = "4xx";
  ResponseType["ServerError"] = "5xx";
  ResponseType["Unknown"] = "Unknown";
})(exports.ResponseType || (exports.ResponseType = {}));

function getResponseType(status) {
  if (status >= 100 && status < 200) {
    return exports.ResponseType.Informational;
  } else if (status >= 200 && status < 300) {
    return exports.ResponseType.Success;
  } else if (status >= 300 && status < 400) {
    return exports.ResponseType.Redirection;
  } else if (status >= 400 && status < 500) {
    return exports.ResponseType.ClientError;
  } else if (status >= 500 && status < 600) {
    return exports.ResponseType.ServerError;
  } else {
    return exports.ResponseType.Unknown;
  }
}

function nonceSource(nonce) {
  return `'nonce-${nonce}'`;
}

function hashSource(hashAlgorithm, value) {
  return `'${hashAlgorithm}-${value}'`;
}

exports.CacheControl = void 0;

(function (CacheControl) {
  CacheControl["NoCache"] = "no-cache";
  CacheControl["NoStore"] = "no-store";
  CacheControl["MustRevalidate"] = "must-revalidate";
  CacheControl["MaxAge"] = "max-age";
})(exports.CacheControl || (exports.CacheControl = {}));

const noCache = `${exports.CacheControl.NoCache},${exports.CacheControl.NoStore},${exports.CacheControl.MustRevalidate},${exports.CacheControl.MaxAge}=0`;
exports.getResponseType = getResponseType;
exports.hashSource = hashSource;
exports.noCache = noCache;
exports.nonceSource = nonceSource;
export default exports;